import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import ow from "images/ow.webp";
import mac from "images/cnc.jpg";
const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded-default md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition-default duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        mac, subtitle: "ABOUT US",
      title: "",
      description:
        "Established in 1973, Goindi Group started manufacturing Machine tools as an import substitute. Employing advanced machining and assembly techniques, the group has become a leader in Indian machine tools industry. The group has further diversified into manufacturing of precision auto components. Using viable synergies the group expanded rapidly and added state of art manufacturing facilities for a wide range of precision components. New plants for forging and casting are under construction. The vision of Goindi group is create a niche of its own in the global market. We are committed to satisfy our customers by manufacturing and supplying quality products with continual up gradation in quality\n" +
        "\n" +
        "The backbone of our vision is a set of core values like  customer satisfaction, teamwork, integrity, professionalism, innovation, excellence, commitment, dynamism and care",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        ow, subtitle: "Mr VIRENDRA GOINDI",
      title: "",
      description:
        " Born entrepreneur who has been captivating speaker at many conferences and events all over India. He passed his engineering in 1962 and has since contributed immensely to the field .\n" +
        "\n" +
        "Yearning for more , he started a 2 year long  internship in design development in Czech Republic\n" +
        "\n" +
        "Facilitated as IMTMA president and now has a permanent seat in the executive board IMTMA\n" +
        "\n" +
        "Morever, he is permanent a guest faculty at IIT-Delhi\n" +
        "\n" +
        "With his never ending enthusiasm and  vigor to learn more he still works aggressively at age of 77",
      url: "https://timerse.com"
    },

  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>GOINDI GROUP | Parishudh Machines Pvt. Ltd.</HeadingTitle>
          <HeadingDescription>
            A TS 16949 2002 CERTIFIED COMPANY.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>

              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};

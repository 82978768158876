import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import Ujjoints from "../../images/ui joins.jpg";
import Differential from "../../images/Differential Housing.webp";
import Yoke from "../../images/yoke.webp";
import Gear from "../../images/Gears.webp";
import GUDGEON from "../../images/GUDGEON.jpg";
import Blower from "../../images/Blower Shaft.jpg";
import Motor from "../../images/Motor  Shafts.webp";
import Fuel from "../../images/Fuel Injection Nozzle.webp";
import Transmission from "../../images/Transmission.webp";
import Shock from "../../images/Shock Absorbers.webp";
import Suspension from "../../images/Suspension.webp";
import CAMSHAFTS from "../../images/main_camshafts.jpg";
import Header, { DesktopNavLinks, LogoLink, NavLink, NavToggle } from "../headers/light";
const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col bg-blue-100  mx-auto shadow-xl max-w-xs items-center px-6 py-10 border-2 border-dashed border-secondary-500 rounded-3xl mt-12`}
  .imageContainer {
    ${tw`w-full  mb-5 flex-shrink-0 bg-white p-2 rounded-lg`}
    img {
     height:200px;
     
    }
  }

  .textContainer {
    ${tw`mt-6 text-center w-full`}
  }

  .title {
    ${tw`mt-2 font-bold w-full bg-white p-3 rounded-lg shadow-xl    text-gray-800`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: Ujjoints,
      title: "UJ JOINTS",
    },
    { imageSrc: CAMSHAFTS, title: "CAMSHAFTS" },
    { imageSrc: Differential, title: "Differential Housing" },
    { imageSrc: Yoke, title: "YOKE" },
    { imageSrc: GUDGEON, title: "GUDGEON PIN" },
    { imageSrc: Gear, title: "Gears" },
    { imageSrc: Blower, title: "Blower Shaft" },
    { imageSrc: Shock, title: "Shock Absorbers" },
    { imageSrc: Suspension, title: "Suspension Parts " },
    { imageSrc: Transmission, title: "Transmission Shafts" },
    { imageSrc: Fuel, title: "Fuel Injection Nozzle" },
    { imageSrc: Motor, title: "Motor  Shafts" },


  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our  <span tw="text-blue-500">Products</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>

              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import MainFeature from "components/features/TwoColWithButton.js";
import Hero from "components/hero/BackgroundAsImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";

import Footer from "components/footers/FiveColumnDark";
import DownloadApp from "components/cta/DownloadApp";
import sice from "images/since.webp";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";

const HighlightedTextInverse = tw.span`bg-gray-100 text-secondary-500 px-4 transform -skew-x-12 inline-block`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;
const Description = tw.span`inline-block mt-8`;
const imageCss = tw`rounded-4xl`;
const TopBar = tw.div`  fixed   bottom-0 inset-x-0   p-1 border-b-default  text-center  text-white z-50`;


export default () => (

  <AnimationRevealPage>
    <TopBar style={{ backdropFilter: 'blur(2px)', background: "rgb(80 ,160,255,0.5)" }}>
      Checkout our <a
        href="/rentals"
      >
        <span
          style={{
            display: "inline-block",
            padding: "0.5rem 0.5rem",
            fontSize: "1rem",
            fontWeight: "bold",
            borderRadius: "0.5rem",
            animation: "flashy-colors 2s linear infinite",
            boxShadow: "0 0 2px rgba(0, 0, 0, 0.3)",
          }}
        >
          <span
            role="img"
            aria-label="party popper"
            style={{
              animation: "flashy-text 0.5s linear infinite",
              transformOrigin: "center",
              animationName: "flashy-text, scale-up",
              animationDuration: "0.5s, 0.2s",
              animationTimingFunction: "linear, ease-out",
              animationIterationCount: "infinite",
              margin: "0 0.25rem",
            }}
          >
            New  Rentals 🎉
          </span>

        </span>
        &nbsp;  here
      </a></TopBar>
    <Hero />




    <MainFeature
      subheading={<Subheading>Established Since 1973</Subheading>}
      heading={
        <>
          We've been serving for
          <wbr /> <HighlightedText>over 5 decades.</HighlightedText>
        </>
      }
      description={
        <Description>
          Goindi Group started manufacturing Machine tools as an import substitute. Employing advanced machining and assembly techniques, the group has become a leader in Indian machine tools industry. The group has further diversified into manufacturing of precision auto components. Using viable synergies the group expanded rapidly and added state of art manufacturing facilities for a wide range of precision components. New plants for forging and casting are under construction. The vision of Goindi group is create a niche of its own in the global market.          The backbone of our vision is a set of core values like  customer satisfaction, teamwork, integrity, professionalism, innovation, excellence, commitment, dynamism and care                  <br />
        </Description>
      }
      buttonRounded={false}
      textOnLeft={false}
      primaryButtonText="Our Products"
      primaryButtonUrl="/products"
      imageSrc={
        sice
      }
      imageCss={imageCss}
      imageDecoratorBlob={true}
      imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
    />
    <MainFeature2
      subheading={<Subheading>VIEWS</Subheading>}
      heading={
        <>
          View Our Award Winning<HighlightedText>Work.</HighlightedText>
        </>
      }
      imageSrc={prototypeIllustrationImageSrc}
      showDecoratorBlob={false}
      primaryButtonUrl="/testimonial"
      features={[
        {
          Icon: MoneyIcon,
          title: "Affordable",
          description: "We promise to offer you the best rate we can - at par with the industry standard.",
          iconContainerCss: tw`bg-green-300 text-green-800`
        },
        {
          Icon: BriefcaseIcon,
          title: "Professionalism",
          description: "We are committed to satisfy our customers by manufacturing and supplying quality products with continual up gradation in quality.",
          iconContainerCss: tw`bg-red-300 text-red-800`
        }
      ]}
    />



    <DownloadApp
      text={<>We have properties on Rent <br /><HighlightedTextInverse>Checkout here</HighlightedTextInverse></>}
    />

    <Footer />


  </AnimationRevealPage>
);

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SimpleIconImage from "images/shield-icon.svg";
import award from "../../images/awards.webp";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw` text-center shadow-xl border-default rounded-2xl p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 flex justify-center  mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl text-center leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=tafe.com",
      title: "Taf Tractors and Farm Equipment LTD",
    },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=tatamotors.com", title: "Tata Motor's LTD" },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=standardcorp.in", title: "Standard Tractors LTD" },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=swarajenterprise.com", title: "Swaraj Engines LTD" },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=solisworld.com", title: "International Tractors LTD" },
    { imageSrc: "https://www.vsttractors.com/sites/default/files/favicon.ico", title: "VST Tillers & Tractors LTD" },
    { imageSrc: SimpleIconImage, title: "Kinetic Motors Company LTD" },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=ge.com", title: "G E Motors LTD" },
    { imageSrc: SimpleIconImage, title: "Motor Industries LTD" },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=continentalengines.com", title: "Continental Engines LTD" },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=lg.com", title: "LG Electronics LTD" },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=godrej.com", title: "Godrej & Boyace MFG CO LTD" },
    { imageSrc: "http://www.google.com/s2/favicons?sz=24&domain=varroc.com", title: "Varroc Engineering LTD" },


  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <h1 tw="text-3xl">OUR</h1>
        <Heading> <span tw="text-yellow-500"> NOTABLE</span> CUSTOMERS</Heading>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <div className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </div>
              <div className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
              </div>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
      <p style={{ "text-align": "center" }}><h1 tw="text-3xl" >Our Awards</h1></p>
      <img width="100%" tw="rounded-t-3xl" src={award} />
    </Container>
  );
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import ImageGallery from "react-image-gallery";
import defaultCardImage from "../../images/machines.png";
import { ReactComponent as X } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as Arr } from "feather-icons/dist/icons/arrow-right.svg";
import { ReactComponent as Message } from "feather-icons/dist/icons/message-square.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 p-6 flex`}
`;

const Card = styled.div`
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 16px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ImageContainer = tw.img`
   mx-auto object-cover rounded-md
`;

const TextContainer = tw.div`
    
  bg-white p-6 relative rounded-lg max-w-screen-sm w-full  mt-6
`;

const Title = tw.span`
uppercase tracking-widest font-bold  
`;

const Description = tw.p`
  font-light text-sm leading-loose text-justify tracking-wide mb-5
`;

const ModalContainer = styled.div`
  ${tw`fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50`}
`;

const ModalContent = styled.div`
  ${tw`bg-white p-6 relative rounded-xl max-w-screen-sm w-full m-6 md:m-0  `}
`;

const ModalTitle = styled.h2`
  ${tw`text-xl font-bold mb-4`}
`;

const ModalImageContainer = styled.div`
  ${tw` place-content-between mb-4 md:flex w-full `}
`;

const ModalImage = styled.img`
  ${tw`w-80 border-default border-gray-300 rounded-lg shadow-sm`}
`;

const ModalSpecs = styled.ul`
  ${tw`list-none w-full my-2 md:m-2 w-full text-center h-80 overflow-x-auto `}
`;

const ModalSpec = styled.li`
  ${tw`   border-b-default my-1    `}
`;

const ContactButton = styled.a`
  ${tw`bg-blue-500 flex  font-black text-xl place-content-between text-white py-2 px-4 rounded-md mt-4`}
`;

const CloseIcon = styled(X)`
  ${tw`rounded-md mt-4 absolute cursor-pointer top-0 right-0 mr-2`}
`;


const DashedBorderSixFeaturesRentals = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      const response = await fetch('/rentals/cards.json');
      const data = await response.json();
      console.log(data);
      setCards(data ?? []);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const ChipContainer = styled.div`
  ${tw`flex items-center  px-2 rounded-full bg-yellow-200 text-yellow-800 border-2 border-yellow-300 ml-2`}
`;

  const ChipText = styled.span`
  ${tw` font-hairline `}
`;

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Our  <span style={{ color: "skyblue" }} >Rentals</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card onClick={() => handleCardClick(card)}>
              <ImageContainer style={{ height: '200px' }} src={(card.imageSrc[0].original ?? defaultCardImage)} alt="" />
              <TextContainer>
                <Title>{card.title || "Fully Secure"}

                </Title>


                <div style={{ color: "skyblue", display: 'flex', placeItems: 'center' }} >click here to View<Arr /><ChipContainer>

                  <ChipText>{card.purpose}</ChipText>
                </ChipContainer></div>
              </TextContainer>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>

      {isModalOpen && (
        <ModalContainer>
          <ModalContent>
            <ModalTitle>{selectedCard.title}</ModalTitle>
            <Description>{selectedCard.description}</Description>

            <ModalImageContainer>
              <ImageGallery style={{ width: "100px !important" }} items={selectedCard.imageSrc} />
              <ModalSpecs>
                {selectedCard.specs.map((spec, i) => (
                  <ModalSpec key={i}>{spec}</ModalSpec>
                ))}
              </ModalSpecs>
            </ModalImageContainer>

            <ContactButton href="/contactus">To know more<Message /></ContactButton>
            <CloseIcon onClick={closeModal}>X</CloseIcon>
          </ModalContent>
        </ModalContainer>
      )}
    </Container>
  );
};

export default DashedBorderSixFeaturesRentals;

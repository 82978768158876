import React from 'react';
import { useParams } from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js"


import Contactus from "pages/ContactUs";
import Products from "pages/Product";
import Testimonial from "pages/Testimonial";
import Aboutus from "pages/Aboutus.js";
import Rentals from 'pages/Rentals';
export const components = {
  contactus: {
    component: Contactus,
  },
  testimonial: {
    component: Testimonial,
  },
  aboutus: {
    component: Aboutus,
  },
  products: {
    component: Products,
  },
  rentals: {
    component: Rentals,
  },
}

export default () => {
  const { type, subtype } = useParams()

  try {
    let Component = null;
    if (type === "blocks" && subtype) {
      Component = components[type][subtype].component
      return <AnimationRevealPage disabled>
        <Component />
      </AnimationRevealPage>
    }
    else
      Component = components[type].component

    if (Component)
      return <Component />

    throw new Error("Component Not Found")
  }
  catch (e) {
    console.log(e)
    return <div>Error: Component Not Found</div>
  }
}

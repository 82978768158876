import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Feature from "components/features/ThreeColWithSideImage"
import Header from "../components/headers/light";
import Footer from "../components/footers/FiveColumnDark";

export default () => (
  <AnimationRevealPage>
    <Header  />

    <Feature/>

    <Footer/>
  </AnimationRevealPage>
);

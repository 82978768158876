import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Feature from "components/features/VerticalWithAlternateImageAndText"
import Footer from "../components/footers/FiveColumnDark";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Feature />
        <Footer/>
    </AnimationRevealPage>
  );
};
